<template>
    <div>
        <div class="qr_contain" ref="qrCodeUrl" id="qrcodeId"></div>
    </div>
</template>  
<script>
import QRCode from 'qrcodejs2';
export default {
    name: 'qrcode',
    // inject: ["newData"],
    data() {
        return {

        }
    },
    props: {
        rowId: {
            type: Number
        }
    },
    created() {
        this.$nextTick(() => {
            //创建二维码
            this.creatQrCode();
        })
    },
    mounted() {

    },
    methods: {
        creatQrCode() {
            new QRCode(this.$refs.qrCodeUrl, {
                text: "https://gbservice.cn/#/detailbk?id=" + this.rowId,
                width: 200,
                height: 200,
                colorDark: "#333333", //二维码颜色
                colorLight: "#ffffff", //二维码背景色
                correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
            })
        }
    },
}
</script>
<style scoped>
.qr_contain {
    display: flex;
    justify-content: center;
}

/deep/ .qr_contain img {
    vertical-align: middle;
    width: 50px;
    height: 50px;
}
</style>
